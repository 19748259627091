import { breakpoint } from "src/shared/styles/variables";
import styled, { keyframes } from "styled-components";

type IProps = {
    active?: boolean
}

export type TGame = {
    show?: boolean;
    active?: boolean;
};

const down = keyframes`
    from  {top:0px}
    to {top:30px}

`;

export const ContainerAboutUs = styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;

    ${breakpoint('xl')`
        width: 950px;
        margin: 0 auto;
    `};
`;

export const Container = styled.div`
     width: 100%;
     position: relative;
           &:before{
            content: '';
            z-indez:-1;
            width: 105%;
            background: #3E3A47;
            border-radius: 10px;
            position: absolute;
            height: 50px;
            top: 60px;
            left: -2.5%;

            ${breakpoint('md')`
            top: 60px;
            `};
           }
`

export const ContainerSlider = styled.div`
    width: 80%;
    margin: auto;
    heigth: 50px;
    justify-content: center;
    align-items: center;
    position: relative;

    .slick-initialized .slick-slide {
        padding-right: 5px;
        padding-left: 5px;
    }

    .slick-slider .slick-list{
        z-index: 5;
        padding-top: 35px;
        padding-bottom: 35px;

    }

    ${breakpoint('md')`
        width: 100%;
    `};
`

export const Header = styled.div`
    width: 50%;
    padding-top: 20px;
    .slider-style {
        .slick-track {
            align-items: center;
            display: flex;
        }

        .slick-list {
            margin: 0 auto;
            width: 90%;
        }
    }

    ${breakpoint('md')`
        display: flex;
        justify-content: space-between;
        width: 95%;
        padding-bottom: 30px;
    `};

    ${breakpoint('xl')`
        width: 50%;
    `};IProps
`;



export const BoxGame = styled.a<TGame>`
    cursor: pointer;
    margin: auto;
    max-width: 96px;
    height: 96px;
    position: relative;
    background: #1d1828;
    border: 1px solid #c5c3c9;
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px;
    /* animation: ${down};
    animation-duration: 2s;
    animation-play-state: ${(props) => (props.show ? 'running' : 'paused')};
    animation-fill-mode: ${(props) => (props.show ? 'forwards' : 'backwards')}; */
    &:hover {
        margin-top: ${(props) => !props.show && '0px'};
        border: 2px solid #00d455;
        transition: all 0.4s 0s ease;
        ${breakpoint('md')`
        margin-top: -30px;
        `};
    }

    &:hover::before {
        ${breakpoint('md')`
            background: transparent;
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 2rem;
        `};
    }

    &.active {
        margin-top: ${(props) => !props.active && '30px'};
        border: 2px solid #00d455;
        transition: all 0.4s 0s ease;
    }
    img {
        width: 50px;
        border-radius: 10px;
        margin: auto;
        overflow: hidden;

    }

    p {
        text-align: center;
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 12px;
    }
`;

export const Arrow = styled.div`
    cursor: pointer;
    position: absolute;
    z-index: 1;
    margin: auto;
    top: 80px;

    button span {
        font-size: 12px;
    }

    &.left {
        left: -28px;
    }
    &.right {
        right: -28px;
    }
`;

export const ArrowTab = styled.div`
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 40px;

    button span {
        font-size: 12px;
    }

    &.left {
        left: -10px;
    }
    &.right {
        right: 0px;
    }

    ${breakpoint('sm')`
        top: 22px;
    `};
`;

export const TabButton = styled.button<IProps>`
    background: transparent;
    border: none;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 14px;
    padding: 20px;

    h2 {
        color: ${(props) => props.theme.colors.accents.base.yellow};
        max-width: 150px;
        margin: 0 auto;
    }

    span {
        margin-right: 5px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    ${breakpoint('md')`
        height: 65px;
        position: relative;
        width: 150px;
        padding: 0;

        h2 {
            color: #56525D;
            transition: all 0.2s ease-in-out;
            margin: 0 auto;
            max-width: 150px;
        }

        span {
            margin-right: 5px;
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }

        ${(props: IProps) =>
            props.active ?
                `
            h2 {
                color: #FFE000;
            }

            &:after {
                content: ' ';
                background: #0DF96C;
                height: 3px;
                width: 100px;
                position: absolute;
                bottom: 6px;
                left: calc(50% - 50px);
            }
            `
                :
                `
            &:hover {
                h2, span {
                    color: #C5C3C9;
                }
            }
            `
        };
    `};
    ${breakpoint('xl')`
        cursor: pointer;
        height: 45px;
        width: auto;
        h2 {
            margin: 0;
            width: auto;
            max-width: 250px;
        }

        ${(props: IProps) =>
            `
            &:after {
                bottom: -4px;
            }
            `
        };
    `};
`;
