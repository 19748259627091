import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperAboutUs = styled.div`
width: 100%;
height: 100%;

${breakpoint('md')({
    padding: '36px',
        margin: 'auto'
})}
`;

export const ContainerAboutUs = styled.div`
    width: 100%;
    height: 100%;
    padding: 44px 23px;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    &::before {
        content: ' ';
        width: 90%;
        height: 0px;
        bottom: 0;
        position: absolute;
        border-top: 1px solid rgb(197, 195, 201);
        bottom: 0;
        left: 0;
        margin: auto;
        right: 0;
    }

    ${breakpoint('md')({
        maxWidth: '705px'
    })}

    ${breakpoint('lg')({
        maxWidth: '995px'
    })}
`;
