import styled from "styled-components"
import { breakpoint } from "src/shared/styles/variables";

export const ContainerTexto = styled.article`
    margin: 0 14px;
    min-height: 270px;
    position: relative;
    background: #1d1828;
    border: 2px solid #00d455;
    box-shadow: 0px 0px 16px 0px rgba(13, 249, 108, 0.50);
    box-sizing: border-box;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-top: 10px;
    position: relative;
    top: -60px;


`

export const SectionTitle = styled.section`
    width: 100%
    display: flex;
    position: relative;
    margin-top: 25px;


    &::before {
        content: ' ';
        width: 90%;
        height: 0px;
        top: 0;
        position: absolute;
        border-top: 2px solid rgb(197, 195, 201);
        bottom: 0;
        left: 0;
        margin: auto;
        right: 0;
    }

`

export const SectionTexto = styled.section`
    justify-content: center;
    display: flex;

    ${breakpoint('md')`
       display: block;
    `};

`

export const SectionFooter = styled.footer`
    width: 100%
    display: flex;
    margin-top: 10px;
    position: relative;
    margin-bottom: 10px;


    ${breakpoint('md')`
        margin-top: 40px;
    `};

    &::before {
        content: ' ';
        width: 90%;
        height: 0px;
        top: 0;
        position: absolute;
        border-top: 2px solid rgb(197, 195, 201);
        bottom: 0;
        left: 0;
        margin: auto;
        right: 0;
    }
`

export const ContainerTitle = styled.div`
    justify-content: center;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 16px;
    position: relative;
    z-index: 5;
    margin-right: auto;
    margin-left: auto;
    max-width: 60%;
    background: rgb(29, 24, 40);
    display: table;
    padding-left: 20px;
    padding-right: 20px;

    span {
        margin-right: 5px;
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }

    ${breakpoint('md')`
        margin-right: auto;
        margin-left: auto;
        font-size: 20px;
    `};
`

export const ContainerFooter = styled.div`
    justify-content: center;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.lf_medium};
    font-size: 12px;
    position: relative;
    z-index: 5;
    margin-right: auto;
    max-width: 60%;
    margin-left: auto;
    background: rgb(29, 24, 40);

    a {
        margin-right: 5px;
        text-decoration: underline;
        color: ${(props) => props.theme.colors.principal.greenBase};
    }

    ${breakpoint('md')`
        margin-right: 200px;
        margin-left: 200px;
        font-size: 16px;
    `};
`

export const ContainerText = styled.div`
    display: flex;
    margin-left: auto;
    margin-top: 20px;
    margin-right: auto;
    text-align: left;
    justify-content: center;
    margin-bottom: 20px;

    ul{
        list-style: disc;
        font-family: ${(props) => props.theme.fonts.lf_medium};
        font-size: 15px;
        padding-left: 1rem;
    }

    a {
        text-decoration: underline;
        color: ${(props) => props.theme.colors.principal.greenBase};
    }

    ${breakpoint('md')`
        margin-right: 50px;

    ul{
        font-size: 16px;
    }
    `};
`

export const Hr = styled.hr`
    border-top: 1px solid rgb(197, 195, 201);
    margin-top: 20px;
`

export const Arrow = styled.div`
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    z-index: 1;

    button span {
        font-size: 12px;
    }

    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
`;

export const ContainerDiv = styled.div `
    margin-right: 20px;
`

export const ContainerCloseModal = styled.div`
    position: absolute;
    top: 15px;
    right: 0px;
    margin-top: 10px;
`