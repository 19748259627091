import React from 'react'
import { Arrow, ContainerCloseModal, ContainerDiv, ContainerFooter, ContainerText, ContainerTexto, ContainerTitle, Hr, SectionFooter, SectionTexto, SectionTitle } from './styled';
import Viewport from 'src/shared/hooks/viewport'
import { colorText } from '@utils/colorText';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Subtitle } from './types';
import { ButtonIcon } from '@componentsShared';
import Slider from 'react-slick';

type IPropsSampleArrow = {
    onClick?: () => void;
    arrow?: string;
};


type IProps = {
    dataSubtitles: Subtitle[];
    dataText: any;
    onClose: () => void;

}



const TipoTexto = (props: IProps) => {
    const viewport = Viewport();
    const getSubtitle = (subtitle: Subtitle) => {
        if (subtitle.name !== null) {
            const title = subtitle.name.replace('___URL___', `<a href="${subtitle.url}" target="_blank"> click aquí </a>`)
            return title

        }

        return null

    }

    const SampleArrow = (props: IPropsSampleArrow) => {
        const { onClick, arrow } = props;

        return (
            <Arrow onClick={onClick} className={arrow}>
                <ButtonIcon
                    icon={`angle-${arrow}`}
                    colorDefault={'#0DF96C'}
                    title={arrow === 'left' ? 'Anterior' : 'Siguiente'}
                    size="small"
                    disabled={false}
                />
            </Arrow>
        );
    };

    const settingsUno = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
        nextArrow: <SampleArrow arrow="right" />,
        prevArrow: <SampleArrow arrow="left" />,

    };

    return (
        <ContainerTexto>
            <SectionTitle >
                <ContainerTitle
                    dangerouslySetInnerHTML={{ __html: colorText(getSubtitle(props.dataSubtitles[0]) || '') }}
                />
            </SectionTitle>
            <SectionTexto >
                {viewport.mobile ? (<Slider {...settingsUno}>
                    {props.dataText.map((question: any, index: number) => {
                        return (
                            <ContainerDiv key={index}>
                                <ContainerText>
                                    {documentToReactComponents(JSON.parse(question.answer.raw))}
                                </ContainerText>
                            </ContainerDiv>
                        )
                    })}
                </Slider>
                ) : (
                    <>
                        {
                            props.dataText.map((question: any, index: number) => {
                                return (
                                    <ContainerText key={index}>
                                        {documentToReactComponents(JSON.parse(question.answer.raw))}
                                    </ContainerText>
                                )
                            })
                        }
                    </>
                )}
            </SectionTexto>
            <SectionFooter>
                <ContainerFooter
                    dangerouslySetInnerHTML={{ __html: colorText(getSubtitle(props.dataSubtitles[1]) || '') }}
                />
            </SectionFooter>
            <ContainerCloseModal>
                <ButtonIcon  icon="cross-small" title='Cerrar' onClick={props.onClose} />
            </ContainerCloseModal>
        </ContainerTexto>
    )
}

export default TipoTexto;