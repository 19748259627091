import React from 'react'
import { Arrow, BoxImagenes, ContainerCloseModal, ContainerImagenes, SectionImages } from './styled';
import { ButtonIcon } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport';
import Slider from 'react-slick';

type IPropsSampleArrow = {
    onClick?: () => void;
    arrow?: string;
};

type IProps = {
    data: any;
    onClose: () => void;

}

const TipoImagenes = (props: IProps) => {

    const viewport = Viewport()

    const SampleArrow = (props: IPropsSampleArrow) => {
        const { onClick, arrow } = props;

        return (
            <Arrow onClick={onClick} className={arrow}>
                <ButtonIcon
                    icon={`angle-${arrow}`}
                    colorDefault={'#0DF96C'}
                    title={arrow === 'left' ? 'Anterior' : 'Siguiente'}
                    size="small"
                    disabled={false}
                />
            </Arrow>
        );
    };

    const settingsUno = {
        infinite: true,
        className: "center",
        slidesToShow: 3,
        swipeToSlide: true,
        arrows: false,
        centerPadding: '20px',
        centerMode: viewport.mobile ? true : false,
        nextArrow: <SampleArrow arrow="right" />,
        prevArrow: <SampleArrow arrow="left" />


    };

    return (

        <ContainerImagenes >
            <SectionImages>
            {viewport.mobile ? (<Slider {...settingsUno} infinite={ props.data.length > 3}>

            {props.data.map((item: any, index: number ) =>{
                return(
                   <BoxImagenes key={index}>
                    <img src={item.file.url} />
                   </BoxImagenes>
                )
            })}
            </Slider>
            ):(
                <>
                {props.data.map((item: any, index: number ) =>{
                    return(
                       <BoxImagenes key={index}>
                        <img src={item.file.url} />
                       </BoxImagenes>
                    )
                })}
                </>

            )}
             </SectionImages>
            <ContainerCloseModal>
                <ButtonIcon  icon="cross-small" title='Cerrar' onClick={props.onClose} />
            </ContainerCloseModal>
        </ContainerImagenes>

    )
}

export default TipoImagenes;