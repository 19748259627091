import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import { ButtonIcon } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport'
import { Arrow, Container, ContainerAboutUs, Header, TabButton, BoxGame, ContainerSlider, ArrowTab } from './styled';
import Slider from 'react-slick';
import { colorText } from '@utils/colorText';
import TipoImagenes from 'src/components/molecules/tipoImagenes';
import { IContentSection } from './types';
import TipoTexto from 'src/components/molecules/tipoTexto';
import TipoTextoImagenes from 'src/components/molecules/tipoTextoImagenes';
import TipoTextoImagenesSencillo from 'src/components/molecules/tipoTextoImagenesSencillo';


type IPropsSampleArrow = {
    onClick?: () => void;
    arrow?: string;
};

const SampleArrow = (props: IPropsSampleArrow) => {
    const { onClick, arrow } = props;

    return (
        <Arrow onClick={onClick} className={arrow}>
            <ButtonIcon
                icon={`angle-${arrow}`}
                colorDefault={'#0DF96C'}
                title={arrow === 'left' ? 'Anterior' : 'Siguiente'}
                size="small"
                disabled={false}
            />
        </Arrow>
    );
};

const SampleArrowTab = (props: IPropsSampleArrow) => {
    const { onClick, arrow } = props;

    return (
        <ArrowTab onClick={onClick} className={arrow}>
            <ButtonIcon
                icon={`angle-${arrow}`}
                colorDefault={'#0DF96C'}
                title={arrow === 'left' ? 'Anterior' : 'Siguiente'}
                size="small"
                disabled={false}
            />
        </ArrowTab>
    );
};


export const ContainerAboutUsTabs = () => {
    const viewport = Viewport();
    const dataQuestions = useStaticQuery(graphql`
    query dataAboutUs {
        allContentfulPaginaInternaFooter(filter: {page: {eq: "QuienesSomos"}}) {
            nodes {
              secondarySection {
                ... on ContentfulSeccionInternaFooter {
                  sectionName
                  sectionContent {
                    ... on  ContentfulCardPortafolio{
                      title,
                      tipo,
                      image{
                        file{
                         url
                        }
                      },
                      imagenes{
                      title,
                       file{
                          url
                      }
                      },
                      textos{
                        question,
                        answer{
                          raw
                        }
                      },
                      subtitulos{
                        name,
                        url
                      },
                    }
                  }
                }
              }
            }
        }
    }
`);
    const { secondarySection } = dataQuestions.allContentfulPaginaInternaFooter.nodes[0];

    const [step, setStep] = useState(0);
    const [sectionContentSelected, setSectionContentSelected] = useState<IContentSection | null>(null);

    const settings = {
        className: 'slider-style',
        infinite: true,
        slidesToShow: 1,
        swipeToSlide: true,
        nextArrow: <SampleArrowTab arrow="right" />,
        prevArrow: <SampleArrowTab arrow="left" />,
        afterChange: (index: number) => {
            setStep(index);
            setId(-1);
            setSectionContentSelected(null)
        }
    };

    const settingsUno = {
        infinite: viewport.mobile ? true: false,
        slidesToShow: viewport.mobile ? 1 : viewport.tablet ? 2 : 6,
        className: 'center',
        swipeToSlide: true,
        arrows: true,
        nextArrow: <SampleArrow arrow="right" />,
        prevArrow: <SampleArrow arrow="left" />,
        responsive: [
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [id, setId] = useState(0);

    const sectionSelected = (section: any) => {
        setSectionContentSelected((prev) => {
            if(prev === section) {
                return null
            }
            if (prev === null) {
                return section
            } else {
                return section
            }

        })
    }

    const renderData = () => {
        switch(sectionContentSelected?.tipo){
            case 'Imagenes' :
                return <TipoImagenes onClose= {()=> {setSectionContentSelected(null)}} data={sectionContentSelected.imagenes} />
            case 'Texto':
                return <TipoTexto onClose= {()=> {setSectionContentSelected(null)}} dataSubtitles={sectionContentSelected.subtitulos} dataText={sectionContentSelected.textos} />
            case 'ImagenesTexto':
                return <TipoTextoImagenes onClose= {()=> {setSectionContentSelected(null)}} data={sectionContentSelected.imagenes} dataText={sectionContentSelected.textos} />
            case 'ImagenesTextoSencillo':
                return <TipoTextoImagenesSencillo onClose= {()=> {setSectionContentSelected(null)}} data={sectionContentSelected.imagenes} dataText={sectionContentSelected.textos} />
            default : return null
        }
    }

    return (
        <ContainerAboutUs>
            <Header>
                {!viewport.desktop && (
                    <Slider {...settings}>
                        {secondarySection.map((item: any, index: any) => {
                            return (
                                <TabButton
                                    key={index}
                                    active={step === index}
                                    onClick={() => {
                                        setId(-1);
                                        setStep(index);
                                        setSectionContentSelected(null)
                                    }}
                                    dangerouslySetInnerHTML={{ __html: colorText(item.sectionName || '') }}
                                />
                            )

                        })
                        }
                    </Slider>
                )}
                {viewport.desktop && (
                    <>
                        {secondarySection.map((item: any, index: any) => (
                            <TabButton
                                key={index}
                                active={step === index}
                                onClick={() => {
                                    setId(-1);
                                    setStep(index);
                                    setSectionContentSelected(null)
                                }}
                                dangerouslySetInnerHTML={{ __html: colorText(item.sectionName || '') }}
                            />
                        ))}
                    </>
                )}
            </Header>
            {secondarySection.map((item: any, index: any) => (

                step === index && (
                    <Container>
                        <ContainerSlider>
                            <Slider {...settingsUno} infinite={viewport.mobile ? true: item.sectionContent.length > 6}>
                                {item.sectionContent.map((section: any, index: any) => {

                                    return (
                                        <>
                                            <BoxGame className={section === sectionContentSelected ? 'active' : ''} key={index} onClick={() => {
                                                sectionSelected(section)
                                            }}
                                            >
                                                <img src={section.image.file.url} alt={section.image.descripcion} />
                                                <p>{section.title}</p>
                                            </BoxGame>


                                        </>
                                    )
                                })}
                            </Slider>
                        </ContainerSlider>
                        {sectionContentSelected !== null && renderData()}
                    </Container>
                )))}
        </ContainerAboutUs>
    )
}

