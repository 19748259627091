import siteUrl from './siteUrl';

interface MetaData {
    title: string;
    descripcion?: string;
    keyWords?: string;
    image?: Image;
}

interface Image {
    file?: File;
    title: string;
    description?: string;
}

interface File {
    url?: string;
    fileName?: string;
}

const getImage = (image?: Image) => {
    return image?.file?.url || undefined;
};

const getSlug = (slug: string): string => {
    if (!slug) {
        return `${siteUrl}/`;
    }
    if (slug[0] === '/') {
        return siteUrl + slug;
    }
    return `${siteUrl}/${slug}`;
};

export const getMetadataPage = (metaData: MetaData, slug: string) => {
    return {
        slug: getSlug(slug),
        title: metaData?.title,
        description: metaData?.descripcion,
        keyWords: metaData?.keyWords,
        image: getImage(metaData?.image)
    };
};
