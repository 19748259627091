import React from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport';
import { ContainerAboutUs, WrapperAboutUs } from './styled';
import { ContentAboutUs } from '@components';
import { graphql, useStaticQuery } from 'gatsby';
import { ContainerAboutUsTabs } from 'src/components/organisms/containerAboutUsTabs';
import { getMetadataPage } from '@utils/metadata';

const AboutUs = () => {
    const viewport = Viewport();
    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'quienes-somos',
            pathname: 'quienes-somos/'
        }
    ];

    const dataAboutUs = useStaticQuery(graphql`
        query aboutUsData {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "QuienesSomos" } }) {
                nodes {
                    slug
                    title
                    urlVideo
                    pageIcon {
                        file {
                            fileName
                            url
                        }
                    }
                    imageText {
                        text {
                            raw
                        }
                        video
                        title
                        mediaFile {
                            file {
                                url
                                fileName
                            }
                        }
                    }
                    metaData {
                        descripcion
                        title
                        keyWords
                        image {
                            file {
                                fileName
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        slug,
        metaData
    } = dataAboutUs.allContentfulPaginaInternaFooter.nodes[0];

    return (
        <Layout>
            <SEO {...getMetadataPage(metaData, slug)} />
            <WrapperAboutUs>
                {viewport.mobile && <ButtonGoBack />}
                {!viewport.mobile && <Breadcrumbs crumbs={crumbs} />}
                <ContainerAboutUs>
                    <ContentAboutUs data={dataAboutUs.allContentfulPaginaInternaFooter.nodes[0]} />
                </ContainerAboutUs>
                <ContainerAboutUsTabs />
            </WrapperAboutUs>
        </Layout>
    );
};

export default AboutUs;
