import styled from "styled-components"
import { breakpoint } from "src/shared/styles/variables";

export const ContainerImagenes = styled.div`
    margin: 0 14px;
    min-height: 291px;
    position: relative;
    background: #1d1828;
    border: 2px solid #00d455;
    box-shadow: 0px 0px 16px 0px rgba(13, 249, 108, 0.50);
    box-sizing: border-box;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    top: -60px;

    ${breakpoint('md')`
    padding: 6px;
   `};
`

export const ContainerText = styled.div`
    margin-top: 20px;
    margin-right: 40px;
    padding-left: 20px;
    font-size: 15px;

    ul{
        list-style: disc;
        font-family: ${(props) => props.theme.fonts.lf_medium};
        font-size: 15px;
    }

    a {
        text-decoration: underline;
        color: ${(props) => props.theme.colors.principal.greenBase};
    }

    ${breakpoint('md')`
        margin-right: 50px;

    `};
`

export const SectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${breakpoint('md')`
     flex-direction: row;
    `};
    `


export const BoxImagenes = styled.div`
    width: 220px;
    height: 100px;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;

    img {
        width: 100%
    }

    ${breakpoint('md')`
        margin-right: 30px;
    `};

`

export const ContainerCloseModal = styled.div`
    position: absolute;
    top: 15px;
    right: 0px;
    margin-top: 10px;

`

export const Hr = styled.hr`
    width: 80%;
    ${breakpoint('md')`
    width: 90%;
   `};
`