import React from 'react'
import { BoxImagenes, ContainerCloseModal, ContainerImagenes, SectionContainer, ContainerText } from './styled';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ButtonIcon } from '@componentsShared';



type IProps = {
    data: any;
    dataText: any;
    onClose: () => void;
}

const TipoTextoImagenesSencillo = (props: IProps) => {

    return (
        <ContainerImagenes >
            <SectionContainer>
                {props.data.map((item: any, index: number) => {
                    return (
                        <BoxImagenes key={index}>
                            <img src={item.file.url} />
                        </BoxImagenes>
                    )
                })}

                {props.dataText.map((question: any, index: number) => {
                    return (
                        <ContainerText key={index}>
                            {documentToReactComponents(JSON.parse(question.answer.raw))}
                        </ContainerText>
                    )
                })}

            </SectionContainer>
            <ContainerCloseModal>
                <ButtonIcon icon="cross-small" title='Cerrar' onClick={props.onClose} />
            </ContainerCloseModal>
        </ContainerImagenes>

    )
}

export default TipoTextoImagenesSencillo;