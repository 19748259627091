import styled from "styled-components"
import { breakpoint } from "src/shared/styles/variables";

export const ContainerImagenes = styled.div`
    margin: 0 14px;
    height: 291px;
    position: relative;
    background: #1d1828;
    border: 2px solid #00d455;
    box-shadow: 0px 0px 16px 0px rgba(13, 249, 108, 0.50);
    box-sizing: border-box;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    display: flex;
    margin-top: 10px;
    top: -60px;

    .slick-list {
        padding-top: 0px !important;
    }
`
export const Arrow = styled.div`
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    z-index: 1;

    button span {
        font-size: 12px;
    }

    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
`;

export const SectionImages = styled.section`
    justify-content: center;
    width: 100%;


    ${breakpoint('md')`
    display: flex;
    flex-wrap: wrap;
    `};

    ${breakpoint('sm')`
        display: flex;
    `};

`

export const BoxImagenes = styled.div`
    width: 84px;
    height: 84px;
    overflow: hidden;
    margin-right: 10px;
    margin-top: 10px;

    img{
        margin: auto;
        border-radius: 10px;
    }

`

export const ContainerCloseModal = styled.div`
    position: absolute;
    top: 15px;
    right: 0px;
    margin-top: 10px;
`